<template>
  <div class="check-issues-table">
    <div class="d-flex flex-wrap align-center">
      <div class="mr-3 mb-3 search-filter-wrapper">
        <v-text-field
          solo
          dense
          clearable
          label="Search..."
          append-icon="mdi-magnify"
          hide-details
          class="no-border"
          data-ga="check_issues_table_search"
          :value="searchText"
          @input="updateSearch"
        ></v-text-field>
      </div>
      <div class="status-filter-wrapper pb-2">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              id="filter-icon"
              size="1.7rem"
              v-on="on"
              v-bind="attrs"
              class="pointer"
              >mdi-filter-variant</v-icon
            >
          </template>
          <span>Filter issue status</span>
        </v-tooltip>
        <!-- Issue status filter -->
        <CustomMenuFilter
          :menuOpen="statusFilterMenuOpen"
          menuActivator="#filter-icon"
          :menuItems="issueStatusMenuItems"
          :selectedMenuItems="selectedStatusFilters"
          :showAll="showAll"
          offset="x"
          filterType="issue status"
          table="check_issues_table"
          @changeFilters="changeStatusFilters"
          @clearSelectedItems="clearItems"
          @setSearchText="setIssueFilterSearchText"
          @clearFilterSearch="clearStatusFilterSearch"
        />
      </div>
      <!-- Appears when checking a checkbox in table to select one or more rows -->
      <div class="d-flex w100">
        <div
          v-if="selectedIssues.length > 0 && filteredIssues.length !== 0"
          class="mr-3 mb-3 search-filter-wrapper d-flex"
        >
          <v-select
            v-model="newStatus"
            clearable
            class="no-border"
            :items="statusOptions"
            item-text="name_external"
            return-object
            label="Select status"
            solo
            dense
            hide-details
          />
        </div>
        <div v-if="selectedIssues.length > 0" class="mr-3 mb-3">
          <v-btn
            color="primary"
            :disabled="newStatus === null"
            :loading="bulkUpdateLoading"
            @click="bulkUpdate()"
            >Submit</v-btn
          >
        </div>
      </div>
    </div>
    <!-- Table that displays issue details for each issue -->
    <div class="data-table">
      <div
        class="d-flex justify-center align-center data-table__spinner"
        v-if="loading || bulkUpdateLoading"
      >
        <v-progress-circular
          :size="40"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-data-table
        v-model="selectedIssues"
        :search="searchText"
        :show-select="true"
        item-key="id"
        :headers="tableHeaders"
        :items="filteredIssues"
        :options.sync="paginationOptions"
        class="no-background checks-table"
        @update:page="updatePaginationOptions($event, 'page')"
        @update:items-per-page="
          updatePaginationOptions($event, 'items_per_page')
        "
        @update:sort-desc="updatePaginationOptions($event, 'sort_by')"
      >
        <template v-slot:header.data-table-select="{ on, props }">
          <div class="d-flex align-center">
            <v-simple-checkbox
              v-if="
                selectedStatusFilters.length === 1 &&
                (selectedStatusFilters.includes('new') ||
                  selectedStatusFilters.includes('unconfirmed'))
              "
              v-bind="props"
              v-on="on"
              :ripple="false"
            ></v-simple-checkbox>
            <span class="text-no-wrap" v-if="selectedIssues.length > 0">
              {{ filteredIssues.length !== 0 ? selectedIssues.length : 0 }}/{{
                filteredIssues.length
              }}
              selected
            </span>
          </div>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              class="pointer"
              :class="{
                'disabled-row': isTableRowCheckboxDisabled(item),
              }"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
            >
              <td>
                <v-tooltip top :disabled="!isTableRowCheckboxDisabled(item)">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-checkbox
                        v-if="
                          item.current_status_def_id === 1 ||
                          item.current_status_def_id === 2
                        "
                        :value="item"
                        hide-details
                        v-model="selectedIssues"
                        :disabled="isTableRowCheckboxDisabled(item)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <span>Can't confirm issues until losses are calculated</span>
                </v-tooltip>
              </td>
              <td
                v-if="checkColumn('turbine_name')"
                @click="handleClickCheck(item)"
              >
                <span class="d-flex">
                  {{ item.turbine_name }}
                </span>
              </td>
              <td
                v-if="checkColumn('current_status_def_name_external')"
                @click="handleClickCheck(item)"
              >
                <div
                  class="status"
                  :style="{
                    'background-color': generateStatusColor(
                      item.current_status_def_id,
                    ),
                  }"
                >
                  {{ item.current_status_def_name_external }}
                </div>
              </td>
              <td v-if="checkColumn('value')" @click="handleClickCheck(item)">
                <span class="d-flex">
                  {{ item.value ? Math.round(item.value) : null }}
                </span>
              </td>
              <td
                class="text-nowrap"
                v-if="checkColumn('start_ts')"
                @click="handleClickCheck(item)"
              >
                {{ dayjs(item.start_ts).format("YYYY-MM-DD") }}
              </td>
              <td
                v-if="checkColumn('loss_upper_usd')"
                @click="handleClickCheck(item)"
              >
                <div
                  v-if="
                    item.loss_upper_usd !== null && item.aep_loss_pct !== null
                  "
                  class="site-name"
                >
                  ${{ roundToString(item.loss_upper_usd, 0) }}
                </div>
              </td>
              <td
                v-if="checkColumn('aep_loss_pct')"
                @click="handleClickCheck(item)"
              >
                <div v-if="item.aep_loss_pct !== null" class="site-name">
                  {{ roundToString(item.aep_loss_pct, 1) }}%
                </div>
              </td>
              <td v-if="checkColumn('gain_upper_usd')">
                <div v-if="item.gain_upper_usd > 0" class="site-name">
                  ${{
                    item.gain_upper_usd
                      ? roundToString(item.gain_upper_usd, -1)
                      : null
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { roundToString, setUrlParams } from "@/helpers/functions";
import {
  statusColors,
  generateStatusColor,
  popupDelay,
  clickCountLimit,
} from "@/helpers/variables";
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import ConfirmDialog from "../components/ConfirmDialog";
import CustomMenuFilter from "./CustomMenuFilter";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

export default {
  name: "CheckIssuesTable",
  emits: ["changeShowMap", "getData"],
  components: { ConfirmDialog, CustomMenuFilter },
  props: {
    /**
     * Checks data to be displayed; passed as props to `DataTable` component
     */
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    /**
     * Loading variable passed to `DataTable` to determine loading status of the table data
     */
    loading: {
      type: Boolean,
      required: true,
    },
    bulkUpdateLoading: {
      type: Boolean,
      required: true,
    },
    /**
     * Built in function passed to `DataTable` designed to select an item in the table with an event
     */
    selectItem: {
      type: Function,
      default: () => {},
    },
    /**
     * Built in function passed to `DataTable` designed to unselect an item in the table with an event
     */
    unselectItem: {
      type: Function,
      default: () => {},
    },
    userHasInternalAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    siteId: {
      type: Number,
      default: null,
      required: false,
    },
    evidenceIndex: {
      type: Number,
      default: 0,
      required: false,
    },
    totalItems: {
      type: Number,
      default: null,
      required: false,
    },
    isCheckDetail: {
      type: Boolean,
      default: false,
      required: false,
    },
    issueStatusFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      /**
       * Checks table headers
       */
      displayColumns: [],
      /**
       * Used to hold search criteria for the checks table
       */
      searchText: "",
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      roundToString,
      generateStatusColor,
      statusColors,
      dayjs,
      popupDelay,
      setUrlParams,
      statusFilters: [
        {
          // Only for internal users
          text: "Unconfirmed",
          value: "unconfirmed",
        },
        {
          text: "Needs review",
          value: "needs_review",
        },
        {
          text: "New",
          value: "new",
        },
        {
          text: "In progress",
          value: "in_progress",
        },
        {
          text: "Fixed",
          value: "fixed",
        },
        {
          text: "Superseded",
          value: "superseded",
        },
        {
          text: "Deferred",
          value: "deferred",
        },
        {
          text: "Dismissed",
          value: "dismissed",
        },
      ],
      statusFilterMenuOpen: false,
      selectedStatusFilters: [],
      selectedIssues: [],
      newStatus: null,
      showUnconfirmed: true,
      showNew: true,
      showOpen: true,
      showClosed: false,
      showDeferred: false,
      tableOptions: {},
      showAll: false,
      issuesFiltered: [],
      searchStatusFilterText: "",
      paginationOptions: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["aep_loss_pct"],
        sortDesc: [1],
      },
      initialSort: false,
    };
  },
  computed: {
    ...mapState({
      issueStatusDefinitions: (state) => state.issues.issueStatusDefinitions,
      isInternal: (state) => state.user.userData.is_internal,
      checksLoading: (state) => state.site.loading.getCheckDetails,
      clickCount: (state) => state.app.clickCount,
    }),
    issueStatusMenuItems() {
      if (this.searchStatusFilterText) {
        return this.statusFilters?.filter((status) =>
          status.value
            .toLowerCase()
            .includes(this.searchStatusFilterText?.toLowerCase()),
        );
      } else {
        return this.statusFilters;
      }
    },
    statusOptions() {
      if (this.selectedIssues.length > 0) {
        if (this.selectedIssues[0].current_status_def_id === 1) {
          // 1 === Unconfirmed
          return this.issueStatusDefinitions.filter(
            (status) => status.id === 2 || status.id === 14, // 2 === New, 14 === Invalidated
          );
        }
        if (this.selectedIssues[0].current_status_def_id === 2) {
          // 2 === New
          return this.issueStatusDefinitions.filter(
            (status) => status.id === 11 || status.id === 3 || status.id === 10, // 11 === Dismissed, 3 === In progress, 10 === Deferred
          );
        }
        if (this.selectedIssues[0].current_status_def_id === 10) {
          // 10 === Deferred
          return this.issueStatusDefinitions.filter(
            (status) => status.id === 3, // 3 === In progress
          );
        }
      }
      return [];
    },
    tableHeaders() {
      let headers = [
        { text: "Turbine", value: "turbine_name" },
        {
          text: "Status",
          value: "current_status_def_name_external",
        },
        { text: "Value", value: "value" },
        { text: "Date found", value: "start_ts" },
        {
          text: "Cumulative loss (USD)",
          value: "loss_upper_usd",
        },
        {
          text: "AEP potential (%)",
          value: "aep_loss_pct",
        },
      ];
      if (this.selectedStatusFilters.includes("fixed")) {
        headers.push({
          text: "Cumulative gains (USD)",
          value: "gain_upper_usd",
        });
      }

      return headers;
    },
    filteredIssues() {
      return this.issuesFiltered;
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
    }),
    setStatusFilterOptions() {
      if (!this.isInternal) {
        const findUnconfirmedIndex = (element) =>
          element.value === "unconfirmed";
        const findNeedsReviewIndex = (element) =>
          element.value === "needs_review";
        const unconfirmedIndex =
          this.statusFilters.findIndex(findUnconfirmedIndex);
        const needsReviewIndex =
          this.statusFilters.findIndex(findNeedsReviewIndex);
        if (unconfirmedIndex > -1) {
          this.statusFilters.splice(this.statusFilters[unconfirmedIndex], 1);
        }
        if (needsReviewIndex > -1) {
          this.statusFilters.splice(this.statusFilters[needsReviewIndex], 1);
        }
      } else {
        this.statusFilters.push({
          text: "Dismissed (internal)",
          value: "dismissed_internal",
        });
        this.statusFilters.push({
          text: "Invalidated",
          value: "invalidated",
        });
      }
    },
    handleClickCheck(item) {
      this.$router.push({
        path: `/turbine-issues/${item.id}`,
      });
    },
    /**
     * Match column header with correct data value
     */
    checkColumn(column) {
      return this.tableHeaders?.find((c) => c.value === column);
    },
    /**
     * Change the search term(s) for the search input
     */
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);
      if (!this.searchText) {
        url.searchParams.delete("search_for");
      }
      history.replaceState(null, null, url);
    },
    setSearchText() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get("search_for");
      this.updateSearch(search);
    },
    async bulkUpdate() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to update the selected issues?",
        )
      ) {
        this.$emit("updateIssueStatus", {
          selectedIssues: this.selectedIssues,
          newStatusId: this.newStatus.id,
        });
        this.selectedIssues = [];
        // Data sent to Google Analytics
        let eventLabel = `bulk_issue_status_changed_to_${this.newStatus.name_external}`;
        const alteredEventLabel = eventLabel.replaceAll(" ", "_");
        if (this.clickCount < clickCountLimit) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: alteredEventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    changeStatusFilters(filter) {
      const url = new URL(window.location.href);
      let newUrl = null;
      let params = {};
      const turbineIssueDefId = +this.$route.params.issueDefId;
      const siteId = +this.$route.params.siteId;

      if (filter === "selectAll") {
        this.clearStatusFilterSearch();
        if (this.selectedStatusFilters?.length !== this.statusFilters.length) {
          this.selectedStatusFilters = [];
          for (const status of this.statusFilters) {
            this.selectedStatusFilters.push(status.value);
          }
          this.showAll = true;
        } else {
          this.selectedStatusFilters = [];
          this.showAll = false;
        }
      } else if (filter === "reset") {
        this.clearStatusFilterSearch();
        if (this.isInternal) {
          this.selectedStatusFilters = [
            "unconfirmed",
            "needs_review",
            "new",
            "in_progress",
          ];
        } else {
          this.selectedStatusFilters = ["new", "in_progress"];
        }
      } else if (Array.isArray(filter)) {
        this.selectedStatusFilters = filter;
      } else {
        if (this.selectedStatusFilters.includes(filter.value)) {
          this.selectedStatusFilters.splice(
            this.selectedStatusFilters.indexOf(filter.value),
            1,
          );
        } else {
          this.selectedStatusFilters.push(filter.value);
        }
        if (this.selectedStatusFilters.length === this.statusFilters.length) {
          this.showAll = true;
        } else {
          this.showAll = false;
        }
      }
      // Group issue filters for filtering issues from data array
      const filters = {
        unconfirmed: [1],
        needs_review: [15],
        new: [2],
        in_progress: [3, 4, 5, 6],
        fixed: [7, 8, 9],
        superseded: [13],
        deferred: [10],
        dismissed: [11],
        dismissed_internal: [16],
        invalidated: [14],
      };
      let idArray = [];
      // Create id array used to filter turbine issues
      for (const status of this.selectedStatusFilters) {
        switch (status) {
          case "unconfirmed":
            idArray.push(filters.unconfirmed);
            break;
          case "needs_review":
            idArray.push(filters.needs_review);
            break;
          case "new":
            idArray.push(filters.new);
            break;
          case "in_progress":
            idArray.push(filters.in_progress);
            break;
          case "fixed":
            idArray.push(filters.fixed);
            break;
          case "superseded":
            idArray.push(filters.superseded);
            break;
          case "deferred":
            idArray.push(filters.deferred);
            break;
          case "dismissed":
            idArray.push(filters.dismissed);
            break;
          case "dismissed_internal":
            idArray.push(filters.dismissed_internal);
            break;
          case "invalidated":
            idArray.push(filters.invalidated);
            break;
        }
      }
      // Set url params for table pagination options
      params.page = this.paginationOptions.page;
      if (this.paginationOptions.itemsPerPage === -1) {
        params.items_per_page = "all";
      } else if (this.paginationOptions.itemsPerPage > -1) {
        params.items_per_page = this.paginationOptions.itemsPerPage;
      }
      params.sort_by = this.paginationOptions.sortBy[0];
      params.sort_desc = this.paginationOptions.sortDesc[0] ? 1 : 0;

      // Filter the turbine issues by status filters
      const ids = idArray.flat();
      if (
        this.data[0]?.turbine_issue_def_id &&
        this.data[0]?.farm_id == siteId &&
        this.data[0]?.turbine_issue_def_id == turbineIssueDefId
      ) {
        this.issuesFiltered = this.data.filter((issue) =>
          ids.includes(issue.current_status_def_id),
        );
        // Delete all url params for statuses before assigning current filters to the url params
        if (ids.length > 0) {
          if (url.searchParams.get("include_status")) {
            url.searchParams.getAll("include_status").forEach(() => {
              url.searchParams.delete("include_status");
            });
          }
          params.include_status = this.selectedStatusFilters;
        }
        newUrl = this.setUrlParams(url, params, "include_status");
        history.replaceState(null, null, newUrl);
        const evidenceIds = this.issuesFiltered.map(
          (issue) => issue.evidence_ids,
        );
        if (evidenceIds.length > 0 && this.issuesFiltered?.length > 0) {
          const flattenedIdArr = evidenceIds.flat();
          const emitParams = {
            ids: flattenedIdArr,
            showEvidence: true,
          };
          this.$emit("showEvidenceView", emitParams);
        } else {
          const emitParams = {
            ids: null,
            showEvidence: false,
          };
          this.$emit("showEvidenceView", emitParams);
        }
      }
    },
    setIssueFiltersByUrl() {
      const url = new URL(window.location.href);
      let statusFilters = url.searchParams.getAll("include_status");
      let pageFromUrl = +url.searchParams.get("page");
      let itemsPerPageFromUrl = url.searchParams.get("items_per_page");
      let sortDescFromUrl = url.searchParams.get("sort_desc");
      let sortByFromUrl = url.searchParams.get("sort_by");
      // Set the pagination options for the data table from the url params
      if (pageFromUrl > 0) {
        this.paginationOptions.page = pageFromUrl;
      } else {
        this.paginationOptions.page = 1;
      }
      if (itemsPerPageFromUrl > 0) {
        this.paginationOptions.itemsPerPage = +itemsPerPageFromUrl;
      } else if (itemsPerPageFromUrl === "all") {
        this.paginationOptions.itemsPerPage = -1;
      } else {
        this.paginationOptions.itemsPerPage = 10;
      }
      if (sortDescFromUrl > -1 && sortDescFromUrl !== null) {
        this.paginationOptions.sortDesc = [sortDescFromUrl];
      } else {
        this.paginationOptions.sortDesc = [1];
      }
      if (sortByFromUrl) {
        this.paginationOptions.sortBy = [sortByFromUrl];
      } else {
        this.paginationOptions.sortBy = ["aep_loss_pct"];
      }
      if (statusFilters?.length === 0) {
        if (this.isInternal) {
          statusFilters = ["unconfirmed", "needs_review", "new", "in_progress"];
        } else {
          statusFilters = ["new", "in_progress"];
        }
      }
      this.changeStatusFilters(statusFilters);
    },
    clearStatusFilterSearch() {
      this.searchStatusFilterText = "";
    },
    clearItems() {
      const url = new URL(window.location.href);
      this.showAll = false;
      this.selectedStatusFilters = [];
      this.staticStatusFilters = [];
      this.issuesFiltered = [];
      // Clear status filters from url params
      const statusFiltersFromUrl = url.searchParams.get("include_status")
        ? url.searchParams.getAll("include_status")
        : [];
      const evidenceFromUrl = url.searchParams.get("evidence");
      if (statusFiltersFromUrl.length > 0) {
        url.searchParams.getAll("include_status").forEach(() => {
          url.searchParams.delete("include_status");
        });
      }
      // Remove evidence params from url and close evidence widget
      if (evidenceFromUrl) {
        url.searchParams.delete("evidence");
      }
      const emitParams = {
        ids: null,
        showEvidence: false,
      };
      this.$emit("showEvidenceView", emitParams);
      this.clearStatusFilterSearch();
      history.replaceState(null, null, url);
    },
    setIssueFilterSearchText(text) {
      this.searchStatusFilterText = text;
    },
    updatePaginationOptions(event, type) {
      // Since the pagination options are synced, only have to set url params on pagination update from the table
      const url = new URL(window.location.href);
      let newUrl = null;
      let params = {};
      if (type === "page") {
        params.page = event;
      }
      if (type === "items_per_page") {
        if (event === -1) {
          params.items_per_page = "all";
        } else {
          params.items_per_page = event;
        }
      }
      if (type === "sort_by") {
        if (this.initialSort) {
          this.initialSort = false;
        } else if (this.paginationOptions.sortDesc[0] > -1) {
          params.sort_by = this.paginationOptions.sortBy[0];
          params.sortDesc = this.paginationOptions.sortDesc[0];
          // Data sent to Google Analytics
          const eventLabel = `check_issue_table_column_sort_${params.sort_by}_${
            params.sortDesc ? "desc" : "asc"
          }`;
          if (this.clickCount < clickCountLimit) {
            this.incrementClickCount();
            gaTrackEvent(this.$gtag, {
              eventName: "first_clicks_after_login",
              eventCategory: "user_interaction",
              eventLabel: eventLabel,
              value: this.clickCount,
            });
          }
        }
      }

      newUrl = this.setUrlParams(url, params);
      history.replaceState(null, null, newUrl);
    },
    isTableRowCheckboxDisabled(item) {
      if (
        item.loss_pct === null &&
        item.current_status_def_name_external.includes("Unconfirmed")
      ) {
        return true;
      }
      if (this.selectedIssues.length > 0) {
        return (
          this.selectedIssues[0].current_status_def_id !==
          item.current_status_def_id
        );
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.setStatusFilterOptions();
    if (this.filteredIssues.length > 0) {
      const ids = this.filteredIssues.map((issue) => issue.evidence_ids);
      const flattenedIdArr = ids.flat();
      const emitParams = {
        ids: flattenedIdArr,
        showEvidence: true,
      };
      this.$emit("showEvidenceView", emitParams);
    }
    this.initialSort = true;
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        const turbineIssueDefId = +this.$route.params.issueDefId;
        const siteId = +this.$route.params.siteId;
        if (
          data?.length > 0 &&
          data[0]?.farm_id == siteId &&
          data[0]?.turbine_issue_def_id == turbineIssueDefId
        ) {
          this.setIssueFiltersByUrl();
          this.selectedIssues = [];
        }
      },
    },
    issueStatusFilters: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.changeStatusFilters(data);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.status {
  border-radius: 4px;
  padding: 6px 8px;
  margin: 6px 0;
  text-align: center;
  display: inline-block;
  color: $black3;
}

.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.issue-filter {
  max-width: 9rem;
}

.columns-filter {
  max-width: 8rem;
}
.data-table {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
  .v-input--checkbox {
    margin-top: 0;
  }
}
.disabled-row {
  background-color: var(--v-hover-base);
  td {
    cursor: not-allowed;
  }
}
::v-deep {
  .switch-section {
    margin-bottom: 10px;
    div {
      margin-right: 4px;
    }
    .v-input {
      padding: 0;
      margin: 0;
    }
    .v-input--selection-controls__input {
      margin: 2px;
    }
    .v-input__slot label {
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
thead.v-data-table-header {
  white-space: normal;
}
.check-issues-table .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
