<template>
  <div class="check-detail">
    <div
      class="check-detail__header d-flex align-center justify-space-between mb-3"
    >
      <h3 class="my-3 d-flex align-center">
        <span
          class="mr-3 main-title"
          v-if="singleCheckDetails && singleCheckDetails.check"
        >
          {{ singleCheckDetails.check.name }}
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="forceRefresh()"
                class="ml-4 icon-hover"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </span>
      </h3>
      <div
        v-if="singleCheckDetails"
        class="check-detail__header__help d-flex align-center justify-space-between"
        @click="handleHelpClick"
      >
        <v-icon>{{ informationIcon }}</v-icon>
        <div v-if="singleCheckDetails && singleCheckDetails.check" class="ml-1">
          {{ singleCheckDetails.check.name || "N/A" }}
        </div>
      </div>
    </div>
    <div class="mb-6">
      <v-row class="widget-row">
        <v-col class="widget-col summary-cards-col">
          <IssueCountCard
            :data="issueCountCard"
            :loading="loading"
            :openIssueTurbineCount="openIssueTurbineCount"
            :turbineCount="turbineCount"
            @goToIssuesTable="goToFilteredIssues"
          />
        </v-col>
        <v-col class="widget-col summary-cards-col">
          <CumulativeLossesCard
            :data="cumulativeLossesCard"
            :loading="loading"
          />
        </v-col>
        <v-col class="widget-col summary-cards-col">
          <AepPotentialCard :data="aepPotentialCard" :loading="loading" />
        </v-col>
        <v-col class="widget-col summary-cards-col">
          <GainsFromFixedCard :data="gainsFromFixedCard" :loading="loading" />
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end">
      <label class="mr-2 pb-4 label">Show map</label>
      <v-switch
        class="mt-0"
        color="primary"
        v-model="showMap"
        @change="changeShowMap"
        :hide-details="false"
      ></v-switch>
    </div>
    <v-row>
      <v-col :lg="showMap ? 9 : 12">
        <CheckIssuesTable
          ref="issuesTable"
          :data="turbineIssuesForCheckDetail"
          :issueStatusFilters="statusFilters"
          :totalItems="totalItems"
          :loading="loadingDetail || bulkUpdateLoading"
          :selectItem="selectItem"
          :unselectItem="unselectItem"
          :evidenceIndex="evidenceIndex"
          :is-check-detail="true"
          :items-per-page="10"
          :bulkUpdateLoading="bulkUpdateLoading"
          @updateIssueStatus="bulkUpdateIssueStatus"
          @showEvidenceView="showEvidenceView"
        />
      </v-col>
      <v-col sm="12" md="12" lg="3">
        <div class="map-switch-anchor">
          <MapView
            v-if="showMap"
            :mapData="mapData"
            :isMapLoading="mapLoading"
          />
        </div>
      </v-col>
    </v-row>
    <!-- MAIN EVIDENCE VIEW -->
    <v-row
      class="mb-4"
      :hidden="loadingDetail"
      v-if="turbinesData && evidenceIdsArr && evidenceIdsArr.length > 0"
    >
      <v-col>
        <v-card style="min-height: 480px" class="evidence-view">
          <v-card-text>
            <div
              class="d-flex justify-center align-center evidence-view__spinner"
              v-if="evidenceLoading"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <div
                class="mr-2 card-title--black3 d-flex justify-space-between text-no-wrap"
              >
                <div class="d-flex">
                  Evidence&nbsp;
                  <div class="evidence-index">
                    ({{ evidenceIndex > -1 ? evidenceIndex + 1 : 0 }}/{{
                      allEvidences.length > 0 ? allEvidences.length : 0
                    }})
                  </div>
                </div>
                <div>
                  <v-tooltip bottom open-delay="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1 pointer"
                        data-ga="check_detail_evidence_full_chart_true"
                        @click="seeFullChart()"
                        >mdi-arrow-expand-all</v-icon
                      >
                    </template>
                    <span>See full chart</span>
                  </v-tooltip>
                </div>
              </div>
              <ChartWidget
                v-if="allEvidences[evidenceIndex]"
                :data="allEvidences[evidenceIndex]"
                :turbineName="turbineName"
              />
              <div class="v-window__prev">
                <button
                  type="button"
                  class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                  aria-label="Previous visual"
                  @click="previous"
                >
                  <span class="v-btn__content"
                    ><i
                      aria-hidden="true"
                      data-ga="check_detail_evidence_carousel_previous_figure"
                      class="v-icon notranslate mdi mdi-chevron-left theme--dark"
                      style="font-size: 36px"
                    ></i
                  ></span>
                </button>
              </div>
              <div class="v-window__next">
                <button
                  type="button"
                  class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                  aria-label="Next visual"
                  @click="next"
                >
                  <span class="v-btn__content"
                    ><i
                      aria-hidden="true"
                      data-ga="check_detail_evidence_carousel_next_figure"
                      class="v-icon notranslate mdi mdi-chevron-right theme--dark"
                      style="font-size: 36px"
                    ></i
                  ></span>
                </button>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- FULL CHART EVIDENCE VIEW -->
    <v-dialog
      v-model="dialog"
      max-width="1140px"
      width="100%"
      hide-overlay
      class="full-chart-modal"
    >
      <v-card min-height="600px">
        <div
          class="d-flex justify-center align-center full-chart-modal__spinner"
          v-if="!allEvidences[evidenceIndex]"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-card-title
            style="border-bottom: 1px solid; border-color: #d8dbe0"
            class="d-flex justify-space-between"
          >
            <div class="d-flex align-center cmodal-title">
              <div v-if="evidenceTitle">
                {{ evidenceTitle }} (Turbine {{ turbineName }})
              </div>
              <div
                v-if="
                  allEvidences[evidenceIndex] &&
                  allEvidences[evidenceIndex].internal
                "
                class="internal-badge"
              >
                INTERNAL
              </div>
            </div>
            <v-btn icon @click="closeDialog">
              <v-icon data-ga="check_detail_evidence_full_chart_false"
                >mdi-close</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-4">
            <EvidenceViewModal
              v-if="allEvidences[evidenceIndex]"
              ref="evidenceModal"
              :loading="evidenceLoading && turbinesData"
              :evidence-index="evidenceIndex"
              :evidence="allEvidences[evidenceIndex]"
              :evidences="allEvidences"
              page="check_detail"
              @updateTitle="updateTitle"
              @updateIndex="updateIndex"
              @clickPrevious="previous"
              @clickNext="next"
            />
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <div
      @click="closeDialog()"
      class="backdrop"
      data-ga="check_detail_evidence_full_chart_false"
      v-if="dialog"
    ></div>
  </div>
</template>

<script>
import CheckIssuesTable from "@/components/CheckIssuesTable";
import MapView from "@/components/MapView";
import IssueCountCard from "@/components/SummaryCards/IssueCountCard";
import AepPotentialCard from "@/components/SummaryCards/AepPotentialCard";
import CumulativeLossesCard from "@/components/SummaryCards/CumulativeLossesCard";
import GainsFromFixedCard from "@/components/SummaryCards/GainsFromFixedCard";
import ChartWidget from "@/components/ChartWidget";
import EvidenceViewModal from "@/components/EvidenceViewModal";
import { mapState, mapActions, mapMutations } from "vuex";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  informationIcon,
} from "@/helpers/variables";
import { roundToString, roundAepPct } from "@/helpers/functions";
import { getPlotlyData } from "@/services/issueService";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "CheckDetail",
  components: {
    CheckIssuesTable,
    MapView,
    IssueCountCard,
    AepPotentialCard,
    CumulativeLossesCard,
    GainsFromFixedCard,
    ChartWidget,
    EvidenceViewModal,
  },
  data() {
    return {
      showMap: true,
      mapLoading: false,
      issueCountCard: {},
      aepPotentialCard: {},
      cumulativeLossesCard: {},
      gainsFromFixedCard: {},
      roundToString,
      loading: false,
      checksDataLoading: false,
      evidenceIndex: 0,
      evidenceLoading: false,
      allEvidences: [],
      bulkUpdateLoading: false,
      dialog: false,
      url: {},
      informationIcon,
      evidenceIdsArr: [],
      mapDisplay: {},
      statusFilters: [],
      bulkUpdateHappened: false,
    };
  },
  props: {
    issueDefId: {
      type: [Number, String],
      default: null,
      required: true,
    },
    siteId: {
      type: [Number, String],
      default: null,
      required: true,
    },
  },
  computed: {
    ...mapState({
      turbinesData: (state) => state.site.turbinesData,
      turbineCount: (state) => state.site.turbinesTotalRecords,
      singleCheckDetails: (state) => state.site.singleCheckDetails,
      evidences: (state) => state.evidence.evidences,
      cachedEvidence: (state) => state.evidence.cachedEvidence,
      clickCount: (state) => state.app.clickCount,
    }),
    totalItems() {
      return this.singleCheckDetails?.turbine_issues?.length;
    },
    openIssueTurbineCount() {
      return this.singleCheckDetails?.summary?.open?.turbine_ids.length;
    },
    lossUSD() {
      if (
        this.singleCheckDetails?.summary?.open?.loss_upper_usd &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(
          this.singleCheckDetails?.summary?.open?.loss_upper_usd,
          -1,
        );
      } else {
        return 0;
      }
    },
    lossMWh() {
      if (
        this.singleCheckDetails &&
        (this.singleCheckDetails?.summary?.open?.loss_mwh ||
          this.singleCheckDetails?.summary?.open?.loss_mwh === 0)
      ) {
        if (this.aepLossPct === 0.0 || this.aepLossPct === "0.0") {
          return 0;
        } else {
          return roundToString(
            +this.singleCheckDetails?.summary?.open?.loss_mwh,
            0,
          );
        }
      } else {
        return 0;
      }
    },
    aepLossUSD() {
      if (
        this.singleCheckDetails?.summary?.open.aep_loss_upper_usdpyr &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(
          this.singleCheckDetails?.summary?.open.aep_loss_upper_usdpyr,
          -1,
        );
      } else {
        return 0;
      }
    },
    lossPct() {
      if (
        this.singleCheckDetails &&
        (this.singleCheckDetails?.summary?.open?.loss_pct ||
          this.singleCheckDetails?.summary?.open?.loss_pct === 0)
      ) {
        if (this.aepLossPct === 0.0 || this.aepLossPct === "0.0") {
          return "0.0";
        } else {
          return (
            "-" +
            roundToString(this.singleCheckDetails?.summary?.open?.loss_pct, 1)
          );
        }
      } else {
        return 0;
      }
    },
    isPlotlyFigure() {
      if (this.evidenceIndex >= 0) {
        for (const evidence of this.evidences) {
          if (
            this.evidences.indexOf(evidence) === this.evidenceIndex &&
            evidence.type.includes("plotly")
          ) {
            return true;
          }
        }
      }

      return false;
    },
    values() {
      return this.singleCheckDetails?.turbine_issues
        .filter((issue) => issue.is_open)
        .map((issue) => issue.value);
    },
    maxValue() {
      return Math.max(...this.values);
    },
    minValue() {
      return Math.min(...this.values);
    },
    evidenceId() {
      if (this.evidenceIdsArr.length > 0) {
        return this.evidenceIdsArr[this.evidenceIndex]
          ? this.evidenceIdsArr[this.evidenceIndex]
          : this.evidenceIdsArr[0];
      }
      return null;
    },
    evidenceTitle() {
      if (this.allEvidences.length > 0 && this.evidenceIndex > -1) {
        return this.allEvidences[this.evidenceIndex]?.title;
      } else {
        return "";
      }
    },
    turbineName() {
      if (
        this.turbinesData?.length > 0 &&
        this.evidenceIndex > -1 &&
        this.allEvidences[this.evidenceIndex] &&
        "turbine_issue_id" in this.allEvidences[this.evidenceIndex] &&
        this.singleCheckDetails?.turbine_issues?.length > 0
      ) {
        return this.getTurbineName();
      } else {
        return "";
      }
    },
    mapData() {
      const mapConfig = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.turbinesData?.length > 0) {
        // eslint-disable-next-line no-undef
        const clonedTurbines = structuredClone(this.turbinesData);
        mapConfig.markers = clonedTurbines;
      }
      return mapConfig;
    },
    turbineIssuesForCheckDetail() {
      if (this.singleCheckDetails?.turbine_issues?.length > 0) {
        return this.singleCheckDetails.turbine_issues;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      getCheckDetails: "site/getCheckDetails",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getEvidences: "evidence/getEvidences",
      cacheEvidence: "evidence/cacheEvidence",
      clearEvidences: "evidence/clearEvidences",
      updateIssueStatus: "issueDetail/updateIssueStatus",
      incrementClickCount: "app/incrementClickCount",
    }),
    ...mapMutations("site", ["clearSiteData"]),
    setWidgetCards() {
      if (
        this.singleCheckDetails &&
        Object.keys(this.singleCheckDetails).length > 0
      ) {
        this.issueCountCard = {
          issue: {
            title: "Issues",
            open: this.singleCheckDetails.summary?.open?.n_open
              ? this.singleCheckDetails.summary?.open?.n_open
              : 0,
            fix: this.singleCheckDetails.summary?.fixed?.n_fixed_or_resolved
              ? this.singleCheckDetails.summary?.fixed?.n_fixed_or_resolved
              : 0,
            attention: this.singleCheckDetails.summary?.open
              ?.n_require_external_input
              ? this.singleCheckDetails.summary?.open?.n_require_external_input
              : 0,
            unconfirmed: this.singleCheckDetails.summary?.unconfirmed?.n
              ? this.singleCheckDetails.summary?.unconfirmed?.n
              : 0,
          },
        };
        this.aepPotentialCard = {
          aepPotential: {
            title: "AEP potential for open issues",
            usd: this.singleCheckDetails.summary?.open?.aep_loss_upper_usdpyr
              ? roundToString(
                  this.singleCheckDetails.summary?.open?.aep_loss_upper_usdpyr,
                  -1,
                )
              : 0,
            energy: this.singleCheckDetails.summary?.open?.aep_loss_mwhpyr
              ? roundToString(
                  this.singleCheckDetails.summary?.open?.aep_loss_mwhpyr,
                  -1,
                )
              : 0,
            percent: this.singleCheckDetails.summary?.open?.aep_loss_pct
              ? roundAepPct(this.singleCheckDetails.summary?.open?.aep_loss_pct)
              : 0.0,
          },
        };
        this.cumulativeLossesCard = {
          title: "Cumulative losses from open issues",
          bodyContent: `$${this.lossUSD}`,
          footerContent: `${this.lossMWh} MWh`,
          footerContentPct: this.lossPct,
        };
        this.gainsFromFixedCard = {
          totalGains: {
            title: "AEP gain from fixed issues",
            usd: this.singleCheckDetails.summary?.fixed?.aep_gain_upper_usdpyr
              ? roundToString(
                  this.singleCheckDetails.summary?.fixed?.aep_gain_upper_usdpyr,
                  -1,
                )
              : 0,
            energy: this.singleCheckDetails.summary?.fixed?.aep_gain_mwhpyr
              ? roundToString(
                  this.singleCheckDetails.summary?.fixed?.aep_gain_mwhpyr,
                  -1,
                )
              : 0,
            percent: this.singleCheckDetails.summary?.fixed?.aep_gain_pct
              ? roundAepPct(
                  this.singleCheckDetails.summary?.fixed?.aep_gain_pct,
                )
              : 0.0,
          },
        };
      }
    },
    closeDialog() {
      const url = new URL(window.location.href);
      url.searchParams.set("evidence_zoomed", 0);
      history.replaceState(null, null, url);
      this.dialog = false;
    },
    changeShowMap(value) {
      this.showMap = value;
      const eventLabel = `check_detail_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    async bulkUpdateIssueStatus(data) {
      this.bulkUpdateLoading = true;
      this.bulkUpdateHappened = true;
      for (let i = 0; i < data.selectedIssues.length; i++) {
        await this.updateIssueStatus({
          issueId: data.selectedIssues[i].id,
          statusDefId: data.newStatusId,
          bulk: true,
        });
      }
      await this.getCheckDetails({
        siteId: this.siteId,
        issueDefId: this.issueDefId,
      });
      this.setWidgetCards();
      await this.getTurbinesBySiteId(this.siteId);
      this.bulkUpdateLoading = false;
    },
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        name: this.singleCheckDetails?.check.name,
        description: this.singleCheckDetails.check?.description,
      });
    },
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    async getEvidence() {
      const url = new URL(window.location.href);
      const params = {
        evidenceId: this.evidenceId,
        evidenceIndex: this.evidenceIndex,
        lengthOfIds: this.evidenceIdsArr.length,
      };
      const cache = this.cachedEvidence.find(
        (evidence) => evidence.id === this.evidenceId,
      );
      // If items are cached, use the cached item
      if (
        this.singleCheckDetails.id == this.$route.params.issueDefId &&
        this.evidenceIdsArr.length > 0 &&
        cache
      ) {
        this.allEvidences[this.evidenceIndex] = cache;
      } else if (this.evidenceIdsArr.length > 0) {
        await this.getEvidences(params);
        this.allEvidences[this.evidenceIndex] = await this.generateEvidenceData(
          this.evidences[this.evidenceIndex],
        );
        this.cacheEvidence(this.allEvidences[this.evidenceIndex]);
      }

      const evidenceFromUrl = url.searchParams.get("evidence");
      if (!evidenceFromUrl) {
        url.searchParams.set(
          "evidence",
          this.allEvidences[this.evidenceIndex].id,
        );
      }
      history.replaceState(null, null, url);
      this.evidenceLoading = false;
    },
    async generateEvidenceData(data) {
      let params = {
        id: null,
        title: "",
        description: "",
        uri: "",
        type: "",
        internal: false,
        turbine_issue_id: null,
        plotlyData: null,
      };
      //  let plotlyData = null;
      if (data?.file_type.includes("plotly")) {
        const res = await getPlotlyData(data.uri);
        if (res.status === "success") {
          params.plotlyData = res.data;
        }
      }

      if (data?.id) {
        params.id = data.id;
        params.title = data.title || "N/A";
        params.description = data.description || "N/A";
        params.uri = data.uri || "";
        params.type = data.file_type;
        params.internal = data.internal;
        params.turbine_issue_id = data.turbine_issue_id;
      }

      return params;
    },
    async previous() {
      const url = new URL(window.location.href);
      if (this.evidenceLoading) return;
      if (this.evidenceIndex > 0) {
        this.evidenceIndex--;
      } else {
        this.evidenceIndex = this.evidenceIdsArr.length - 1;
      }
      url.searchParams.set("evidence", this.evidenceId);
      history.replaceState(null, null, url);
      if (this.evidenceIdsArr.length > 0) {
        this.evidenceLoading = true;
        const found = this.allEvidences.some(
          (evidence) => evidence?.id === this.evidenceId,
        );
        if (!found) {
          await this.getEvidence();
        }
        this.evidenceLoading = false;
      }
    },
    async next() {
      // Have to instantiate current url here to avoid replacing previous params
      const url = new URL(window.location.href);
      if (this.evidenceLoading) return;
      if (this.evidenceIndex < this.evidenceIdsArr.length - 1) {
        this.evidenceIndex++;
      } else {
        this.evidenceIndex = 0;
      }
      // Set url param for evidence
      url.searchParams.set("evidence", this.evidenceId);
      history.replaceState(null, null, url);
      if (this.evidenceIdsArr.length > 0) {
        this.evidenceLoading = true;
        const found = this.allEvidences.some(
          (evidence) => evidence?.id === this.evidenceId,
        );
        if (!found) {
          await this.getEvidence();
        }
        this.evidenceLoading = false;
      }
    },
    getTurbineName() {
      for (const item of this.singleCheckDetails?.turbine_issues) {
        if (
          item.id === this.allEvidences[this.evidenceIndex].turbine_issue_id
        ) {
          return item.turbine_name;
        }
      }
      return "";
    },
    updateTitle(value) {
      this.title = value.title;
    },
    updateIndex(value) {
      this.evidenceIndex = value;
    },
    seeFullChart() {
      const url = new URL(window.location.href);
      if (+url.searchParams.get("evidence_zoomed") === 0) {
        url.searchParams.set("evidence_zoomed", 1);
        history.replaceState(null, null, url);
      }
      this.dialog = true;
    },
    showEvidenceView(payload) {
      const url = new URL(window.location.href);
      const idFromUrl = +url.searchParams?.get("evidence");
      const zoomed = +url.searchParams?.get("evidence_zoomed");
      this.evidenceIdsArr = payload.ids;
      if (this.evidenceIdsArr?.length > 0) {
        this.evidenceLoading = true;
        // Create array with predetermined length to insert retrieved evidence in same index as its
        // matching id from the evidenceIdsArr array
        this.allEvidences = Array(this.evidenceIdsArr.length);

        if (idFromUrl && this.evidenceIdsArr.includes(idFromUrl)) {
          this.evidenceIndex = this.evidenceIdsArr.indexOf(idFromUrl);
        } else {
          this.evidenceIndex = 0;
        }
        this.getEvidence();
        if (zoomed) {
          this.seeFullChart();
        }
      } else {
        this.allEvidences = [];
        if (idFromUrl) {
          url.searchParams.delete("evidence");
        }
        history.replaceState(null, null, url);
      }
    },
    goToFilteredIssues(payload) {
      if (payload === "open") {
        this.statusFilters = ["new", "in_progress"];
      } else if (payload === "unconfirmed") {
        this.statusFilters = ["unconfirmed", "needs_review"];
      } else {
        this.statusFilters = [payload];
      }
    },
    async forceRefresh() {
      this.loading = true;
      this.loadingDetail = true;
      this.mapLoading = true;
      await this.getCheckDetails({
        siteId: this.siteId,
        issueDefId: this.issueDefId,
      });
      this.setWidgetCards();
      await this.getTurbinesBySiteId(this.siteId);
      this.loading = false;
      this.loadingDetail = false;
      this.mapLoading = false;
    },
  },
  async beforeMount() {
    const siteId = +this.$route.params.siteId;
    const issueDefId = +this.$route.params.issueDefId;
    this.loading = true;
    this.mapLoading = true;
    this.evidenceLoading = true;
    this.loadingDetail = true;
    if (
      this.singleCheckDetails?.id !== issueDefId ||
      this.singleCheckDetails?.site_id !== siteId ||
      !this.singleCheckDetails
    ) {
      await this.getCheckDetails({
        siteId: this.siteId,
        issueDefId: this.issueDefId,
      });
    }
    this.setWidgetCards();
    await this.getTurbinesBySiteId(this.siteId);
    this.mapLoading = false;
    this.loadingDetail = false;
    this.loading = false;
  },
  beforeDestroy() {
    if (this.bulkUpdateHappened) {
      this.clearSiteData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.check-detail {
  &__header {
    &__help {
      background-color: var(--v-search-base);
      border: 1px solid var(--v-border-base);
      color: var(--v-black3-base);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 6px 16px 6px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
    }
  }
}

.evidence-view {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}

.full-chart-modal {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}
</style>
