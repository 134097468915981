<template>
  <div class="chart-widget d-flex justify-center">
    <div class="chart-widget__content d-flex flex-column justify-center">
      <div
        class="plotly-overlay"
        v-if="data.type.includes('plotly') && isEdit"
      ></div>
      <div class="d-flex">
        <h4 class="chart-widget__content__title text-h6">
          {{ data.title }} (Turbine {{ turbineName }})
          <span v-if="data.internal" class="internal-badge">INTERNAL</span>
        </h4>
      </div>
      <p class="chart-widget__content__description">{{ data.description }}</p>
    </div>
    <div class="chart-widget__evidence" v-if="data.uri">
      <div
        class="image-overlay"
        v-if="(data.type === 'image' || data.file_type === 'image') && isEdit"
      ></div>
      <EvidenceView :data="data" />
    </div>
  </div>
</template>

<script>
import EvidenceView from "./EvidenceView";
/**
 * A chart widget to show turbine issue evidence.
 */
export default {
  name: "ChartWidget",
  emits: ["seeFullChart"],
  components: {
    EvidenceView,
  },
  props: {
    /**
     * Turbine issue evidence passed to the `EvidenceView.vue` component
     */
    data: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    turbineName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      title: this.data.title,
      description: this.data.description,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.chart-widget {
  position: relative;
  &__content {
    max-width: 200px;
    width: 200px;
    flex: 1;

    &__title {
      color: var(--v-black2-base);
      font-size: 1rem !important;
      line-height: 1.2rem;
      font-weight: 600;
      font-family: "Museo Sans Rounded" !important;
    }

    &__description {
      color: var(--v-black5-base);
      font-size: 0.875rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__evidence {
    img {
      width: 100%;
      height: auto;
    }
  }

  .plotly-overlay,
  .image-overlay {
    position: absolute;
    background: rgba(128, 128, 128, 0.5);
    z-index: 50;
  }

  .plotly-overlay {
    top: 0;
    left: 32.5%;
    height: 100%;
    width: 550px;
  }

  .image-overlay {
    top: 0;
    bottom: 0;
    left: 46.5%;
    width: 430px;
  }
}
</style>

<style lang="scss">
.issue-detail {
  .row {
    margin: 0;
  }
  .v-responsive {
    overflow-x: auto;
  }
}
</style>
