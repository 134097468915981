<template>
  <div>
    <img v-if="isValidImage()" :src="getUri()" alt="Evidence" height="380" />
    <Plotly
      id="plotly_diagram"
      v-if="isValidPlotly()"
      :data="carouselData.plotlyData.data"
      :layout="carouselData.plotlyData.layout"
      :responsive="true"
      :display-mode-bar="true"
      :showTips="false"
    ></Plotly>
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";
/**
 * Displays a carousel of plotly images detailing a turbine issue
 */
export default {
  name: "EvidenceView",
  components: {
    Plotly,
  },
  props: {
    /**
     * Contains the data to be displayed, including the plotly image
     */
    data: {
      type: Object,
      required: true,
    },
    plotlyHeight: {
      type: Number,
      required: false,
      default: null,
    },
    plotlyWidth: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      hoverPlotly: false,
    };
  },
  computed: {
    /**
     * Sets the layout for the carousel view of evidence images
     */
    carouselData() {
      let temp = null;
      let dataObj = {};
      if (this.data && this.dataHasMultipleProperties) {
        dataObj = this.data.current ? this.data.current : this.data.snapshot;
      } else if (this.data && !this.dataHasMultipleProperties) {
        dataObj = this.data;
      }
      // eslint-disable-next-line no-undef
      temp = structuredClone(dataObj);

      if (temp?.plotlyData) {
        temp.plotlyData.layout.legend = {
          orientation: "h",
          y: -0.25,
          x: 0,
          traceorder: "normal",
          font: {
            size: 12,
            color: this.$vuetify.theme.isDark ? "#fff" : "#000",
          },
          bordercolor: "#FFFFFF",
          borderwidth: 1,
        };
        temp.plotlyData.layout.autosize = true;
        temp.plotlyData.layout.paper_bgcolor = this.$vuetify.theme.isDark
          ? "#1e1e1e"
          : "";
        temp.plotlyData.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };
        temp.plotlyData.layout.title = null;
        if (this.plotlyHeight) {
          temp.plotlyData.layout.height = this.plotlyHeight;
        } else {
          temp.plotlyData.layout.height = "450";
        }
        if (this.plotlyWidth) {
          temp.plotlyData.layout.width = this.plotlyWidth;
        } else {
          temp.plotlyData.layout.width = "750";
        }
      }

      return temp;
    },
    dataHasMultipleProperties() {
      if (this.data && "current" in this.data) {
        return true;
      }
      return false;
    },
  },
  methods: {
    isValidPlotly() {
      if (
        (this.data?.current && this.data?.current?.type?.includes("plotly")) ||
        (!this.data?.current &&
          this.data?.snapshot &&
          this.data?.snapshot?.type?.includes("plotly")) ||
        this.data?.type?.includes("plotly")
      ) {
        return true;
      }
      return false;
    },
    isValidImage() {
      if (
        (this.data?.current &&
          (this.data?.current?.type === "image" ||
            this.data?.current?.file_type === "image")) ||
        (!this.data?.current &&
          this.data?.snapshot &&
          (this.data?.snapshot?.type === "image" ||
            this.data?.snapshot?.file_type === "image")) ||
        (("type" in this.data || "file_type" in this.data) &&
          (this.data?.type === "image" || this.data?.file_type === "image"))
      ) {
        return true;
      }
      return false;
    },
    getUri() {
      const isValid = this.isValidImage();
      if (isValid) {
        if (this.dataHasMultipleProperties) {
          return this.data.current
            ? this.data.current.uri
            : this.data.snapshot.uri;
        } else {
          return this.data.uri;
        }
      }
    },
  },
};
</script>
