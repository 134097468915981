<template>
  <v-card outlined class="summary-widget h100">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <div class="d-flex align-center">
        <p class="widget-title widget-header">{{ data.title }}</p>
        <PopUp
          v-if="
            data.popUpData &&
            !Object.values(data.popUpData).every((x) => x === null)
          "
          :content="[data.title]"
          class="losses-gains-popup"
        >
          <template v-slot:hover>
            <v-icon style="transform: scale(0.8)">{{ informationIcon }}</v-icon>
          </template>
          <template v-slot:content>
            <div v-if="data.popUpData.modelType">
              {{ data.popUpData.type }} model type:
              {{ data.popUpData.modelType }}
            </div>
            <div v-if="data.popUpData.data_start_ts">
              {{ data.popUpData.type }} data start:
              {{ data.popUpData.data_start_ts }} (UTC)
            </div>
            <div v-if="data.popUpData.data_end_ts">
              {{ data.popUpData.type }} data end:
              {{ data.popUpData.data_end_ts }} (UTC)
            </div>
            <div v-if="data.popUpData.loss_period_total_energy_mwh">
              Total energy in loss period:
              {{ data.popUpData.loss_period_total_energy_mwh }}
            </div>
          </template>
        </PopUp>
      </div>
      <div class="d-flex align-center">
        <h3 class="mr-2 text-h7">
          {{ data.bodyContent }}
        </h3>
      </div>
      <p v-if="data.footerContent" class="widget-title widget-footer">
        <span class="mr-2">{{ data.footerContent }}</span>
        <span
          v-if="data.footerContentPct || data.footerContentPct === 0"
          :class="[
            data.footerContentPct > 0 ? 'increase' : 'decrease',
            'price-percent',
          ]"
          class="mr-2"
        >
          {{ data.footerContentPct }}%
        </span>
      </p>
    </div>
  </v-card>
</template>

<script>
import PopUp from "@/components/PopUp";
import { roundToString } from "@/helpers/functions";
import { informationIcon, summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "CumulativeLossesCard",
  components: {
    PopUp,
  },
  data() {
    return { roundToString, informationIcon, summaryWidgetSpinnerSize };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.losses-gains-popup {
  .popup {
    max-width: 350px;
    right: -150px;
  }
}
</style>
