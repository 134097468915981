<template>
  <div>
    <div class="w-100 evidence-modal" v-if="modalData">
      <div class="evidence-text-container">
        <p>{{ modalData.description }}</p>

        <p class="click-zoom" v-if="modalData.type.includes('plotly')">
          <i
            >Click and drag to zoom in to a selection on the plot. Double click
            to reset.</i
          >
        </p>
      </div>
      <div class="d-flex justify-center align-center">
        <div class="full-chart-image">
          <img
            v-if="modalData.type === 'image'"
            :src="modalData.uri"
            alt="Evidence"
            class="limited-image"
          />
        </div>
        <Plotly
          v-if="modalData.type.includes('plotly') && modalData.plotlyData"
          :data="modalData.plotlyData.data"
          :layout="modalData.plotlyData.layout"
          :responsive="true"
          :display-mode-bar="true"
        ></Plotly>
      </div>
      <div class="v-window__prev">
        <button
          type="button"
          class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
          aria-label="Previous visual"
          @click="previous"
        >
          <span class="v-btn__content"
            ><i
              aria-hidden="true"
              :data-ga="`${page}_evidence_full_chart_carousel_previous_figure`"
              class="v-icon notranslate mdi mdi-chevron-left theme--dark"
              style="font-size: 36px"
            ></i
          ></span>
        </button>
      </div>
      <div class="v-window__next">
        <button
          type="button"
          class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
          aria-label="Next visual"
          @click="next"
        >
          <span class="v-btn__content"
            ><i
              aria-hidden="true"
              :data-ga="`${page}_evidence_full_chart_carousel_next_figure`"
              class="v-icon notranslate mdi mdi-chevron-right theme--dark"
              style="font-size: 36px"
            ></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "EvidenceViewModal",
  components: {
    Plotly,
  },
  props: {
    evidenceIndex: {
      type: Number,
      required: true,
    },
    evidence: {
      type: Object,
      required: true,
    },
    evidences: {
      type: Array,
      required: true,
    },
    showCurrentEvidence: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: String,
      required: false,
      default: "issue_detail",
    },
  },
  data() {
    return {
      hoverPlotly: false,
      columns: 1,
    };
  },
  computed: {
    modalData() {
      let temp = null;
      // Clone either the current or snapshot object for image editing
      if (
        this.showCurrentEvidence ||
        this.evidences[this.index]?.current?.show
      ) {
        // eslint-disable-next-line no-undef
        temp = structuredClone(this.evidences[this.index]?.current);
      } else if (this.evidences[this.index]?.snapshot?.show) {
        // eslint-disable-next-line no-undef
        temp = structuredClone(this.evidences[this.index]?.snapshot);
      } else {
        // eslint-disable-next-line no-undef
        temp = structuredClone(this.evidences[this.index]);
      }

      if (temp?.plotlyData) {
        temp.plotlyData.layout.legend = {
          orientation: "h",
          y: -0.25,
          traceorder: "normal",
          font: {
            size: 12,
            color: this.$vuetify.theme.isDark ? "#fff" : "#000",
          },
          bordercolor: "#FFFFFF",
          borderwidth: 1,
        };
        temp.plotlyData.layout.paper_bgcolor = this.$vuetify.theme.isDark
          ? "#1e1e1e"
          : "";
        temp.plotlyData.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };
        temp.plotlyData.layout.autosize = true;
        temp.plotlyData.layout.width = "1000";
        temp.plotlyData.layout.height = "450";
        temp.plotlyData.layout.title = null;
      }

      return temp;
    },
    index() {
      if (this.evidenceIndex >= 0 && this.evidences.length > 0) {
        return this.evidenceIndex;
      } else {
        return 0;
      }
    },
  },
  watch: {
    index: {
      immediate: true,
      handler(data) {
        const updateObj = {
          currentIndex: data,
        };
        if (this.showCurrentEvidence || this.evidences[data]?.current?.show) {
          updateObj.title = this.evidences[data]?.current.title;
        } else if (this.evidences[data]?.snapshot?.show) {
          updateObj.title = this.evidences[data]?.snapshot.title;
        } else {
          updateObj.title = this.evidences[data]?.title;
        }
        this.$emit("updateTitle", updateObj);
      },
    },
    modalData: {
      immediate: true,
      handler(data) {
        const updateObj = {
          currentIndex: this.index,
        };
        if (data) {
          if (
            this.showCurrentEvidence ||
            this.evidences[this.index]?.current?.show
          ) {
            updateObj.title = this.evidences[this.index]?.current.title;
          } else if (this.evidences[this.index]?.snapshot?.title) {
            updateObj.title = this.evidences[this.index]?.snapshot.title;
          } else {
            updateObj.title = this.evidences[this.index].title;
          }
          this.$emit("updateTitle", updateObj);
        }
      },
    },
  },
  methods: {
    previous() {
      const url = new URL(window.location.href);
      if (this.index > 0) {
        url.searchParams.set("evidence", this.index - 1);
      } else {
        url.searchParams.set("evidence", this.evidences.length - 1);
      }
      history.replaceState(null, null, url);
      this.$emit("clickPrevious", this.index);
    },
    next() {
      const url = new URL(window.location.href);
      if (this.index < this.evidences.length - 1) {
        url.searchParams.set("evidence", this.index + 1);
      } else {
        url.searchParams.set("evidence", 0);
      }
      history.replaceState(null, null, url);
      this.$emit("clickNext", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.full-chart-image {
  max-width: 90%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.limited-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>

<style lang="scss">
.evidence-text-container {
  z-index: 20;

  .click-zoom {
    margin-bottom: 0 !important;
  }
}
.evidence-modal .col {
  padding-top: 0 !important;
}
</style>
